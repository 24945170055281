export const MEGABYTE = 1000 * 1000; // Backend uses 1000 instead of 1024
export const MAX_FILE_SIZE = 10 * MEGABYTE;
export const MAX_FILE_SIZE_TEXT = '10MB';
export const FILE_IGNORE_LIST = ['.DS_Store'];
export const MARKDOWN_WRAPPER_CLASS = 'article-body';
export const STORAGE_KEY_PROJECTS_SORT = 'projects_sort';
export const STORAGE_KEY_PROJECTS_VIEW = 'projects_view';
export const STORAGE_KEY_EDITOR_PREFERENCES = 'editor_preferences';
export const STORAGE_KEY_ITEMS_PER_PAGE = 'items_per_page';
export const STORAGE_KEY_WHATS_NEW_NOTIFICATION_BADGE = 'has-viewed-whats-new-dec-5-2024';
export const STORAGE_KEY_BANNER = 'pyscript-dismissed-banners';
