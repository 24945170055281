import { defineStore } from 'pinia';
import pyscriptApi from '~/utilities/pyscript-api';
import type { SystemStatus } from '~/utilities/pyscript-api-models';

export const useSystemStore = defineStore('system', {
  state: (): SystemStatus => ({
    banners: [],
    maintenance_mode: false,
  }),

  actions: {
    async fetchSystemStatus() {
      try {
        const response = await pyscriptApi.getSystemStatus();

        this.banners = response.banners;
        this.maintenance_mode = response.maintenance_mode;

        return response;
      } catch (error) {} // Probably should do something here
    },
  },
});
