/**
 * Send the user to the 404 page while preserving the route they're trying to visit.
 *
 * E.g. If the route visited is `/some/fake/route#hash?some_query=value` the 404 page
 * will appear, but the route is unchanged.
 */
export function show404Page() {
  const route = window.routerInstance.currentRoute.value;

  return window.routerInstance.replace({
    name: 'not-found',
    // preserve current path and remove the first char to avoid the target URL starting with `//`
    params: { pathMatch: route.path.substring(1).split('/') },
    // preserve existing query and hash if any
    query: route.query,
    hash: route.hash,
  });
}
